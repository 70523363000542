const REGEX = {
  PASSPORT: /^(?!^0+$)[a-zA-Z0-9]{3,20}$/,
  PHONE_NO:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  URL: /((?:https?:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/i,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  isinReg: '^[A-Z]{2}([A-Z0-9]){9}[0-9]$',
  ageReg: /^(0?[1-9]|[1-9][0-9]|[1][1-9][1-9]|200)$/,
  incomeReg: /^(?:0|[1-9]\d*)(?:\.(?!.*000)\d+)?$/,
  specialCharReg: /^[a-zA-Z0-9]+$/,
  numReg: /^[0-9]+$/,
  alphabetsReg: /^[A-Za-z\s]+$/,
  alertReg: /^\d*$/
};
export default REGEX;
