/* eslint-disable camelcase */
/* eslint-disable no-shadow */
// @ts-nocheck
import { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';
import useInitiate from './useInitiate';
import useRiskRating from './useRiskRating';
import useCommonReducer from './useCommonReducer';
import useTransactionScreening from './useTransactionScreening';
import useInitialScreening from './useInitialScreening';

/**
 * @function useOngoingScreening
 * @description a custom hook for Ongoing Screening page
 * @returns {object} object
 */
export default function useOngoingScreening() {
  const { uploadAttachedFiles, uploadFileOns3 } = useInitialScreening();
  const {
    getRiskParameters,
    industry,
    product,
    occupation,
    crrMandatoryParameterList,
  } = useInitiate();
  const { getTransactionParameters, channel, productType, transactionType } =
    useTransactionScreening();
  const {
    fetchRiskList,
    calculateRiskRating,
    riskList,
    riskValue,
    riskResult,
    setRiskList,
    setRiskValue,
    systemRisk,
    setSystemRisk,
    riskResponse,
  } = useRiskRating();
  const {
    dispatch,
    setInitialPageIndexOngoingMonitoring,
    setInitialPageSizeOngoingMonitoring,
  } = useCommonReducer();
  const { enqueueSnackbar } = useSnackbar();
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sanctionsList, setSanctionsList] = useState([]);
  const [pepList, setPepList] = useState([]);
  const [amsList, setAmsList] = useState([]);
  const [legalList, setLegalList] = useState([]);
  const [corporateList, setCorporateList] = useState([]);
  const [legalDocument, setLegalDocument] = useState([]);
  const [CustomerHistoryList, setCustomerHistoryList] = useState([]);
  const [fetchUsersPageCount, setFetchUsersPageCount] = useState(0);
  const [hasData, setHasData] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalCustomer, setTotalCustomer] = useState();
  const [disabled, setdisabled] = useState(true);
  const [cardDetails, setCardDetails] = useState({});
  const [analystsList, setAnalystsList] = useState([]);
  const [referralList, setReferralList] = useState([]);
  const [riskDetails, setRiskDetails] = useState([]);
  const fetchIdRef = useRef(0);
  const { user } = useSelector(selectAuth);

  /**
   * @function resetAllList
   * @description A function to reset all lists
   * @returns {undefined}
   * @example resetAllList()
   */
  const resetAllList = () => {
    setSanctionsList([]);
    setPepList([]);
    setAmsList([]);
    setLegalList([]);
    setCorporateList([]);
  };
  /**
   * @function fetchUserList
   * @description A function to fetch User List
   * @returns {Promise} returns an axios promise
   */
  const fetchUserList = () => {
    setIsLoading(true);
    axios
      .get(API_ENDPOINTS.ONGOING_SCREENING_ALL_ANALYST)
      .then((response) => {
        setAnalystsList(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchReferralsList
   * @description A function to fetch Referrals List
   * @returns {Promise} returns an axios promise
   */
  const fetchReferralsList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(API_ENDPOINTS.ONGOING_SCREENING_REFERRALS, {
        params: queryParams,
      })
      .then((response) => {
        setReferralList(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchUserList();
  }, []);
  /**
   * @function fetchUsers
   * @description A function to fetch Users data
   * @returns {Promise} returns an axios promise
   */
  const fetchUsers = useCallback(
    async ({
      pageSize,
      pageIndex,
      query,
      orderBy,
      status,
      days,
      checkerLevel,
      formData,
      alertList,
      alert,
      bookmark,
      team_type,
      channel,
      product,
      transaction_type,
      bookmarked,
    }) => {
      // Give this fetch an ID
      fetchIdRef.current += 1;
      const fetchId = fetchIdRef.current;

      // Your server could send back total page count.
      setIsLoading(true);

      try {
        const response = await axios.get(
          API_ENDPOINTS.ONGOING_SCREENING_ALERT_LIST,
          {
            params: {
              page: pageIndex + 1,
              screen: 'ongoing',
              ...(pageSize ? { pagesize: pageSize } : {}),
              query: query?.trim(),
              orderBy,
              checkerLevel,
              status,
              days,
              ...formData,
              alert: alert || '',
              alert_list: alertList ? alertList.join().toLowerCase() : '',
              ...(!bookmarked && {
                bookmark: bookmark !== '' ? bookmark : false,
              }),
              team_type,
              channel,
              product,
              transaction_type,
            },
            loader: !query?.length,
          }
        );

        setIsLoading(false);

        if (fetchId === fetchIdRef.current) {
          setUserList(response.data);
          setTotalUsers(response.headers['x-total-count']);
          setFetchUsersPageCount(
            Math.ceil(response.headers['x-total-count'] / pageSize)
          );

          // Check if the last page is empty, and move to the previous page if needed
          if (response?.data?.length === 0 && pageIndex > 0) {
            const newPageIndex = Math.max(pageIndex - 1, 0);
            dispatch(setInitialPageSizeOngoingMonitoring(pageSize));
            dispatch(setInitialPageIndexOngoingMonitoring(newPageIndex));
            return fetchUsers({
              pageSize,
              pageIndex: newPageIndex,
              query,
              orderBy,
              status,
              days,
              checkerLevel,
              formData,
              alertList,
              alert,
              team_type,
            });
          }

          if (!Number(response.headers['x-total-count'])) {
            setHasData(false);
          }
        }

        return response;
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    },
    []
  );

  /**
 * @function handleReview
 * @description A function to fetch review details
 * @returns {Promise} returns an axios promise
 */
  const handleReview = (queryParams) => {
    setIsLoading(true);
    return axios
      .post(
        `${API_ENDPOINTS.ONGOING_REVIEW_DETAILS}${queryParams.search_id}/`
      )
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            enqueueSnackbar(response?.data?.message, {
              variant: 'success',
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        },
        (response) => {
          setIsLoading(false);
          enqueueSnackbar(response?.response?.data?.message, {
            variant: 'error',
          });
        }
      );
  };

  /**
   * @function fetchCardData
   * @description a function to fetch card data
   * @param {object} queryParams
   * @returns {Promise} returns an axios promise
   */
  const fetchCardData = (queryParams) => {
    setIsLoading(true);
    resetAllList();
    return axios
      .get(
        `${API_ENDPOINTS.ONGOING_SCREENING_CARD_DETAILS}${queryParams.search_id}/`
      )
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            setCardDetails(response?.data);
          }
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function getRiskRating
   * @description a function to get risk rating fields data
   * @param {object} queryParams
   * @returns {Promise} returns an axios promise
   */
  const getRiskRating = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.UPDATE_RISK_RATING_FIELDS, {
        params: {
          review_id: queryParams,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          if (response?.data) {
            setRiskDetails(response?.data);
          }
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function updateRiskRating
   * @description a function to update risk rating fields data
   * @param {object} data - data object
   * @returns {Promise} returns an axios promise
   */
  const updateRiskRating = (data) => {
    setIsLoading(true);
    const payload = {
      previous_search_id: data.search_id,
      ...data,
    };
    return axios.put(API_ENDPOINTS.UPDATE_RISK_RATING_FIELDS, payload).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('Search has been successfully reinitiated.', {
          variant: 'success',
        });
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  /**
   * @function updateUser
   * @description a function to update user's data
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const updateUser = (data) => {
    setIsLoading(true);
    axios
      .patch(`${API_ENDPOINTS.ONGOING_SCREENING_CARD_DETAILS}${data.id}/`, data)
      .then(
        (response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setdisabled(!disabled);
            enqueueSnackbar(response.data.Msg, {
              variant: 'success',
            });
          }
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function fetchSanctionsList
   * @description a function to fetch sanctions list
   * @param {object} queryParams
   * @returns {Promise} returns an axios promise
   */
  const fetchSanctionsList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.ONGOING_SCREENING_CARD_DETAILS}${queryParams.id}/sanction`
      )
      .then(
        (response) => {
          setSanctionsList(response.data);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function fetchPepList
   * @description a function to fetch PEP list
   * @param {object} queryParams
   * @returns {Promise} returns an axios promise
   */
  const fetchPepList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.ONGOING_SCREENING_CARD_DETAILS}${queryParams.id}/pep`
      )
      .then(
        (response) => {
          setIsLoading(false);
          setPepList([...response.data]);
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function fetchAmsList
   * @description a function to fetch AMS list
   * @param {object} queryParams
   * @returns {Promise} returns an axios promise
   */
  const fetchAmsList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.ONGOING_SCREENING_CARD_DETAILS}${queryParams.id}/ams`
      )
      .then(
        (response) => {
          setAmsList([...response.data]);
          setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function fetchCustomerHistoryList
   * @description a function to fetch customer history list
   * @param {object} params
   * @returns {Promise} returns an axios promise
   */
  const fetchCustomerHistoryList = (params) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.ONGOING_SCREENING_SUMMARY_CUSTOMER_INFO, {
        params: {
          screen: 'ongoing',
          id: params.id,
          page: params.pageIndex + 1,
          pagesize: params.pageSize,
        },
      })
      .then(
        (response) => {
          setIsLoading(false);
          setCustomerHistoryList(response?.data);
          return response;
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function fetchLegalList
   * @description A function to fetch legal List
   * @returns {Promise} returns an axios promise
   */
  const fetchLegalList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.ONGOING_SCREENING_CARD_DETAILS}${queryParams.id}/legal`
      )
      .then((response) => {
        setLegalList([...response.data]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchCorporateList
   * @description A function to fetch corporate List
   * @returns {Promise} returns an axios promise
   */
  const fetchCorporateList = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.ONGOING_SCREENING_CARD_DETAILS}${queryParams.id}/corporate`
      )
      .then((response) => {
        setCorporateList([...response.data]);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  /**
   * @function fetchLegalDocument
   * @description A function to fetch legal document
   * @returns {Promise} returns an axios promise
   */
  const fetchLegalDocument = (queryParams) => {
    setIsLoading(true);
    axios
      .get(
        `${API_ENDPOINTS.ONGOING_SCREENING_SUMMARY_LEGAL_DOCUMENT}${queryParams.tid}`
      )
      .then((response) => {
        setIsLoading(false);
        setLegalDocument(response?.data?.legal_documents);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  /**
   * @function updateListsRow
   * @description a function to update list rows
   * @param {object} listType
   * @param {number} index
   * @param {string} value
   * @return {object} prevState
   */
  const updateListsRow = ({ index, value }, listType) => {
    switch (listType) {
      case 'sanctions':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_result[index].status = value;
          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_country_result[index].status = value;
          return { ...updatedList };
        });
        break;
      case 'pep':
        setPepList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'ams':
        setAmsList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].status = value;
          return [...updatedList];
        });
        break;
      case 'risk':
        setRiskList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].classification = value;
          return [...updatedList];
        });
        break;
      default:
    }
  };
  const updateAllListsRow = (listType, mode) => {
    switch (listType) {
      case 'sanctions':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_result = updatedList.sanction_result.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_country_result = updatedList.sanction_country_result.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return { ...updatedList };
        });
        break;
      case 'pep':
        setPepList((prevState) => {
          const updatedList = prevState.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return updatedList;
        });
        break;
      case 'ams':

        setAmsList((prevState) => {
          const updatedList = prevState.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return updatedList;
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = prevState.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return updatedList;
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = prevState.map((item) => ({
            ...item,
            status: mode ? 'selected' : mode === null ? false : 'notSelected',
          }));
          return updatedList;
        });
        break;
      case 'risk':
        setRiskList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].classification = value;
          return [...updatedList];
        });
        break;
      default:
    }
  };
  /**
   * @function updateListsCommentRow
   * @description A function for updating comments from Lists Row
   * @returns {Promise} returns an axios promise
   */
  const updateListsCommentRow = ({ index, value }, listType) => {
    switch (listType) {
      case 'sanctions':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_result[index].comments = value;
          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          updatedList.sanction_country_result[index].comments = value;
          return { ...updatedList };
        });
        break;
      case 'pep':
        setPepList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      case 'ams':
        setAmsList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = [...prevState];
          updatedList[index].comments = value;
          return [...updatedList];
        });
        break;
      default:
    }
  };

  function getObjectIndexById(array, id) {
    return array?.findIndex((obj) => obj?.id === id);
  }
  const updateAttachmentInCommentRow = (
    fileDetails,
    URL,
    listType,
    operationType = 'add'
  ) => {
    switch (listType) {
      case 'sanctionList':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          const index = getObjectIndexById(
            sanctionsList?.sanction_result,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList.sanction_result[index].attached_file = fileDetails;
            updatedList.sanction_result[index].attached_file_url = URL;
          } else {
            updatedList.sanction_result[index].attached_file = '';
            updatedList.sanction_result[index].attached_file_url = URL;
          }

          return { ...updatedList };
        });
        break;
      case 'sanctionsCountry':
        setSanctionsList((prevState) => {
          const updatedList = { ...prevState };
          const index = getObjectIndexById(
            sanctionsList?.sanction_country_result,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList.sanction_country_result[index].attached_file =
              fileDetails;

            updatedList.sanction_country_result[index].attached_file_url = URL;
          } else {
            updatedList.sanction_country_result[index].attached_file = '';
            updatedList.sanction_country_result[index].attached_file_url = URL;
          }

          return { ...updatedList };
        });
        break;
      case 'pepList':
        setPepList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            pepList,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }

          return [...updatedList];
        });
        break;
      case 'amsList':
        setAmsList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            amsList,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }
          return [...prevState];
        });
        break;
      case 'legal':
        setLegalList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            prevState,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }
          return [...updatedList];
        });
        break;
      case 'corporate':
        setCorporateList((prevState) => {
          const updatedList = [...prevState];
          const index = getObjectIndexById(
            prevState,
            fileDetails?.split('*')?.[0]
          );
          if (operationType !== 'delete') {
            updatedList[index].attached_file = fileDetails;
            updatedList[index].attached_file_url = URL;
          } else {
            updatedList[index].attached_file = '';
            updatedList[index].attached_file_url = URL;
          }
          return [...updatedList];
        });
        break;
      default:
    }
  };
  /**
   * @function submitSummaryVerdict
   * @description a function to submit a summary
   * @param {object} data
   * @returns {Promise} returns an axios promise
   */
  const submitSummaryVerdict = (data) => {
    setIsLoading(true);
    const payload = {
      remarks: data.remarks,
      status: (data.verdict?.toLowerCase())[0] === 'a' ? 'a' : 'r',
      results: {
        ams_result: data.ams_result,
        pep_result: data.pep_result,
        sanction_result: data.sanction_result,
        sanction_country_result: data.sanction_country_result,
        legal_result: data.legal_result,
        corporate_result: data.corporate_result,
        ...(data?.risk
          ? {
              risk_rating_result: data?.risk_rating_result,
              risk_type: data?.risk_type,
              risk_rating: data?.risk_rating,
            }
          : {}),
      },
      attachment: data?.attachment,
    };
    return axios
      .post(
        `${API_ENDPOINTS.ONGOING_SCREENING_SUMMARY_VERDICT}${data.id}/`,
        payload,
        {
          params: { analyst: data.analyst, level: data.level },
        }
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Verdict submitted successfully.', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function submitRequestReferral
   * @description A function to submit Request Referral
   * @returns {Promise} returns an axios promise
   */
  const submitRequestReferral = (data) => {
    setIsLoading(true);
    const payload = {
      description: data.remarks,
      requested_to: data.analystName,
      attachment: data?.attachment,
      results: {
        sanction_result: data.sanction_result,
        pep_result: data.pep_result,
        ams_result: data.ams_result,
        corporate_result: data.corporate_result,
        legal_result: data.legal_result,
        sanction_country_result: data?.sanction_country_result,
        risk_rating_result: data?.risk_rating_result,
      },
    };
    return axios
      .post(
        `${API_ENDPOINTS.ONGOING_SCREENING_REQUEST_ANALYST}${data.id}/`,
        payload
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Refer sent successfully.', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  /**
   * @function submitReferral
   * @description A function submit Referral
   * @returns {Promise} returns an axios promise
   */
  const submitReferral = (data) => {
    setIsLoading(true);
    const payload = {
      comments: data.remarks,
      attachment: data?.attachment,
    };
    return axios
      .post(
        `${API_ENDPOINTS.INITIAL_SCREENING_REQUEST_ANALYST}${data.id}/`,
        payload
      )
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Refer sent successfully.', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(false);
        }
      );
  };
  const deleteFile = ({ id, row, type }) => {
    const data = { filename: `${id}*${row?.attached_file}` };
    return axios
      .delete(`${API_ENDPOINTS.DELETE_FILE}`, { params: data })
      .then((res) => {
        updateAttachmentInCommentRow(
          `${id}*${row?.attached_file}`,
          '',
          type,
          'delete'
        );
        return res;
      })
      .catch((error) => {
        enqueueSnackbar(error?.response?.data?.error, {
          variant: 'error',
        });
        throw error;
      });
  };

  return {
    user,
    fetchUsers,
    userList,
    setUserList,
    updateUser,
    fetchSanctionsList,
    fetchPepList,
    fetchAmsList,
    fetchLegalList,
    fetchCorporateList,
    fetchCustomerHistoryList,
    sanctionsList,
    pepList,
    amsList,
    legalList,
    corporateList,
    legalDocument,
    fetchLegalDocument,
    CustomerHistoryList,
    updateListsRow,
    isLoading,
    submitSummaryVerdict,
    fetchUsersPageCount,
    totalUsers,
    disabled,
    setdisabled,
    totalCustomer,
    setTotalCustomer,
    fetchCardData,
    cardDetails,
    setCardDetails,
    analystsList,
    fetchReferralsList,
    submitReferral,
    submitRequestReferral,
    updateListsCommentRow,
    referralList,
    hasData,
    setHasData,
    riskList,
    riskValue,
    riskResult,
    fetchRiskList,
    calculateRiskRating,
    getRiskParameters,
    industry,
    product,
    occupation,
    setRiskValue,
    setRiskList,
    systemRisk,
    setSystemRisk,
    riskResponse,
    riskDetails,
    getRiskRating,
    updateRiskRating,
    crrMandatoryParameterList,
    getTransactionParameters,
    channel,
    productType,
    transactionType,
    updateAttachmentInCommentRow,
    uploadAttachedFiles,
    deleteFile,
    uploadFileOns3,
    handleReview,
    updateAllListsRow
  };
}
