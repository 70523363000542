const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AU', label: 'Australia', phone: '61', suggested: true },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  { code: 'CA', label: 'Canada', phone: '1', suggested: true },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
  { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
  { code: 'CF', label: 'Central African Republic', phone: '236' },
  { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  { code: 'DE', label: 'Germany', phone: '49', suggested: true },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
  { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  { code: 'FR', label: 'France', phone: '33', suggested: true },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  { code: 'JP', label: 'Japan', phone: '81', suggested: true },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  { code: 'PS', label: 'Palestine, State of', phone: '970' },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  { code: 'TD', label: 'Chad', phone: '235' },
  { code: 'TF', label: 'French Southern Territories', phone: '262' },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
  { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  { code: 'US', label: 'United States', phone: '1', suggested: true },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
  { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];
const CURRENCIES = [
  { currency: 'United States Dollar', code: 'USD', symbol: '$' },
  { currency: 'Euro', code: 'EUR', symbol: '€' },
  { currency: 'British Pound Sterling', code: 'GBP', symbol: '£' },
  { currency: 'Japanese Yen', code: 'JPY', symbol: '¥' },
  { currency: 'Australian Dollar', code: 'AUD', symbol: 'A$' },
  { currency: 'Canadian Dollar', code: 'CAD', symbol: 'C$' },
  { currency: 'Swiss Franc', code: 'CHF', symbol: 'Fr' },
  { currency: 'Chinese Yuan Renminbi', code: 'CNY', symbol: '¥' },
  { currency: 'Indian Rupee', code: 'INR', symbol: '₹' },
  { currency: 'South Korean Won', code: 'KRW', symbol: '₩' },
  { currency: 'Russian Ruble', code: 'RUB', symbol: '₽' },
  { currency: 'Brazilian Real', code: 'BRL', symbol: 'R$' },
  { currency: 'Mexican Peso', code: 'MXN', symbol: '$' },
  { currency: 'South African Rand', code: 'ZAR', symbol: 'R' },
  { currency: 'Saudi Riyal', code: 'SAR', symbol: '﷼' },
  { currency: 'United Arab Emirates Dirham', code: 'AED', symbol: 'د.إ' },
  { currency: 'Singapore Dollar', code: 'SGD', symbol: 'S$' },
  { currency: 'Hong Kong Dollar', code: 'HKD', symbol: 'HK$' },
  { currency: 'New Zealand Dollar', code: 'NZD', symbol: 'NZ$' },
  { currency: 'Swedish Krona', code: 'SEK', symbol: 'kr' },
  { currency: 'Norwegian Krone', code: 'NOK', symbol: 'kr' },
  { currency: 'Danish Krone', code: 'DKK', symbol: 'kr' },
  { currency: 'Turkish Lira', code: 'TRY', symbol: '₺' },
  { currency: 'Egyptian Pound', code: 'EGP', symbol: 'E£' },
  { currency: 'Argentine Peso', code: 'ARS', symbol: '$' },
  { currency: 'Colombian Peso', code: 'COP', symbol: '$' },
  { currency: 'Malaysian Ringgit', code: 'MYR', symbol: 'RM' },
  { currency: 'Thai Baht', code: 'THB', symbol: '฿' },
  { currency: 'Indonesian Rupiah', code: 'IDR', symbol: 'Rp' },
  { currency: 'Pakistani Rupee', code: 'PKR', symbol: '₨' },
  { currency: 'Nigerian Naira', code: 'NGN', symbol: '₦' },
  { currency: 'Kenyan Shilling', code: 'KES', symbol: 'KSh' },
  { currency: 'Swiss Franc', code: 'CHF', symbol: 'Fr' },
  { currency: 'New Taiwan Dollar', code: 'TWD', symbol: 'NT$' },
  { currency: 'Israeli New Shekel', code: 'ILS', symbol: '₪' },
  { currency: 'Qatari Riyal', code: 'QAR', symbol: '﷼' },
  { currency: 'Kuwaiti Dinar', code: 'KWD', symbol: 'د.ك' },
  { currency: 'Omani Rial', code: 'OMR', symbol: '﷼' },
  { currency: 'Bahraini Dinar', code: 'BHD', symbol: 'د.ب' },
];

const days = [
  {
    value: 0,
  },
  {
    value: 20,
    label: '20 Days',
  },
  {
    value: 40,
    label: '40 Days',
  },
  {
    value: 60,
    label: '60 Days',
  },
  {
    value: 80,
    label: '80 Days',
  },
  {
    value: 100,
  },
];
const marks = [
  {
    value: 0,
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 99,
    label: '99%',
  },
];
const riskRatingMarks = [
  {
    value: 0,
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 60,
    label: '60',
  },
];
const reminderMailMarks = [
  {
    value: 0,
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 30,
    label: '30',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 60,
    label: '60',
  },
];
const snoozingFrequencyMarks = [
  {
    value: 0,
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 16,
    label: '16',
  },
  {
    value: 20,
    label: '20',
  },
];
const alertBySamsMark = [
  {
    value: 0,
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];
const dobMarks = [
  {
    value: 0,
  },
  {
    value: 20,
    label: '3m',
  },
  {
    value: 40,
    label: '9m',
  },
  {
    value: 60,
    label: '15m',
  },
  {
    value: 80,
    label: '24m',
  },
  {
    value: 99,
    label: '36m',
  },
];

const SEARCH_TYPES = {
  b: {
    key: 'b',
    label: 'Default',
  },
  i: {
    key: 'i',
    label: 'Individual',
  },
  e: {
    key: 'e',
    label: 'Entity',
  },
  v: {
    key: 'v',
    label: 'Vessel',
  },
  a: {
    key: 'a',
    label: 'Aircraft',
  },
};

const SEARCH_TYPES_TRANSACTION = {
  b: {
    key: 'b',
    label: 'Default',
  },
  i: {
    key: 'i',
    label: 'Individual',
  },
  e: {
    key: 'e',
    label: 'Entity',
  },
};

const SCREEN_TYPES = {
  i: {
    key: 'i',
    label: 'Initial',
  },
  o: {
    key: 'o',
    label: 'ongoing',
  },
};

const ASSIGNED_OPTIONS = {
  a: {
    key: 'a',
    label: 'Alert',
  },
  na: {
    key: 'na',
    label: 'Non Alert',
  },
  n: {
    key: 'n',
    label: 'Nothing',
  },
  b: {
    key: 'b',
    label: 'Both',
  },
};

const FINAL_VERDICTS = {
  a: {
    key: 'a',
    label: 'Accepted',
  },
  r: {
    key: 'r',
    label: 'Rejected',
  },
};

const LIST_TYPES = {
  s: {
    key: 's',
    label: 'Sanctions',
  },
  p: {
    key: 'p',
    label: 'PEP',
  },
  a: {
    key: 'a',
    label: 'AMS',
  },
  c: {
    key: 'c',
    label: 'Corporate',
  },
  l: {
    key: 'l',
    label: 'Legal',
  },
};
// ONGOING WITHOUT RISK RATING PERMISSION
const BULK_FILE_LIST_TYPE_COLUMNS = {
  i: [
    'S.no',
    'Full Name',
    'Gender',
    'D.O.B (yyyy/mm/dd)',
    'Nationality',
    'SSN',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'Phone No',
    'Email',
    'PAN',
    'National ID',
    'Alias',
    'Ongoing Monitoring End date',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
  e: [
    'S.no',
    'Org Name',
    'D.O.E (yyyy/mm/dd)',
    'Phone No',
    'Email',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Headquarters',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'CIN',
    'GST',
    'ISIN',
    'TIN/PAN',
    'Alias',
    'Ongoing Monitoring End date',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
  v: [
    'S.no',
    'Vessel Name',
    'Mfd date (yyyy/mm/dd)',
    'Vessel Type',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Flag State',
    'Country of Jurisdiction',
    'Registered Owner',
    'Parent Company',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'IMO',
    'MMSI',
    'Alias',
    'Ongoing Monitoring End date',
  ],
  a: [
    'S.no',
    'Aircraft Name',
    'Mfd Date (yyyy/mm/dd)',
    'Program',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Aircraft Model',
    'Country of Jurisdiction',
    'Operator',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'Manufacture Serial no',
    'Construction Number',
    'Alias',
    'Ongoing Monitoring End date',
  ],
  b: [
    'S.no',
    'Name',
    'Alias',
    'DOB/DOM/DOE(yyyy/mm/dd)',
    'Nationality',
    'Gender',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'TIN/PAN',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'National ID',
    'Phone No',
    'Email',
    'Headquarters',
    'CIN',
    'GST',
    'ISIN',
    'SSN',
    'Vessel Type',
    'Flag State',
    'MMSI',
    'Registered Owner',
    'Tonnage',
    'IMO',
    'Parent Company',
    'Program',
    'Aircraft Operator/Owner',
    'Aircraft Model',
    'Manufacture Serial no',
    'Construction Number',
    'Ongoing Monitoring End date',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
};
// INITITALL WITHOUT RISK RATING
const BULK_INITIAL_FILE_LIST_TYPE_COLUMNS = {
  i: [
    'S.no',
    'Full Name',
    'Gender',
    'D.O.B (yyyy/mm/dd)',
    'Nationality',
    'SSN',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'Phone No',
    'Email',
    'PAN',
    'National ID',
    'Alias',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
  e: [
    'S.no',
    'Org Name',
    'D.O.E (yyyy/mm/dd)',
    'Phone No',
    'Email',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Headquarters',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'CIN',
    'GST',
    'ISIN',
    'TIN/PAN',
    'Alias',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
  v: [
    'S.no',
    'Vessel Name',
    'Mfd date (yyyy/mm/dd)',
    'Vessel Type',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Flag State',
    'Country of Jurisdiction',
    'Registered Owner',
    'Parent Company',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'IMO',
    'MMSI',
    'Alias',
  ],
  a: [
    'S.no',
    'Aircraft Name',
    'Mfd Date (yyyy/mm/dd)',
    'Program',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Aircraft Model',
    'Country of Jurisdiction',
    'Operator',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'Manufacture Serial no',
    'Construction Number',
    'Alias',
  ],
  b: [
    'S.no',
    'Name',
    'Alias',
    'DOB/DOM/DOE(yyyy/mm/dd)',
    'Nationality',
    'Gender',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'TIN/PAN',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'National ID',
    'Phone No',
    'Email',
    'Headquarters',
    'CIN',
    'GST',
    'ISIN',
    'SSN',
    'Vessel Type',
    'Flag State',
    'MMSI',
    'Registered Owner',
    'Tonnage',
    'IMO',
    'Parent Company',
    'Program',
    'Aircraft Operator/Owner',
    'Aircraft Model',
    'Manufacture Serial no',
    'Construction Number',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
};
// ONGOING WITH RISK RATING PERMISSION
const BULK_FILE_RISK_LIST_TYPE_COLUMNS = {
  i: [
    'S.no',
    'Full Name',
    'Gender',
    'D.O.B (yyyy/mm/dd)',
    'Nationality',
    'SSN',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'Phone No',
    'Email',
    'PAN',
    'National ID',
    'Alias',
    'Ongoing Monitoring End date',
    'Age',
    'Occupation',
    'Income',
    'Product Type',
    'Onboarding Duration',
    'Onboarding Type',
    'Missed Payment',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
  e: [
    'S.no',
    'Org Name',
    'D.O.E (yyyy/mm/dd)',
    'Phone No',
    'Email',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Headquarters',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'CIN',
    'GST',
    'ISIN',
    'TIN/PAN',
    'Alias',
    'Ongoing Monitoring End date',
    'Incorporation',
    'Industry',
    'Income',
    'Product Type',
    'Onboarding Duration',
    'Onboarding Type',
    'Missed Payment',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
  v: [
    'S.no',
    'Vessel Name',
    'Mfd date (yyyy/mm/dd)',
    'Vessel Type',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Flag State',
    'Country of Jurisdiction',
    'Registered Owner',
    'Parent Company',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'IMO',
    'MMSI',
    'Alias',
    'Ongoing Monitoring End date',
  ],
  a: [
    'S.no',
    'Aircraft Name',
    'Mfd Date (yyyy/mm/dd)',
    'Program',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Aircraft Model',
    'Country of Jurisdiction',
    'Operator',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'Manufacture Serial no',
    'Construction Number',
    'Alias',
    'Ongoing Monitoring End date',
  ],
  b: [
    'S.no',
    'Name',
    'Alias',
    'DOB/DOM/DOE(yyyy/mm/dd)',
    'Nationality',
    'Gender',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'TIN/PAN',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'National ID',
    'Phone No',
    'Email',
    'Headquarters',
    'CIN',
    'GST',
    'ISIN',
    'SSN',
    'Vessel Type',
    'Flag State',
    'MMSI',
    'Registered Owner',
    'Tonnage',
    'IMO',
    'Parent Company',
    'Program',
    'Aircraft Operator/Owner',
    'Aircraft Model',
    'Manufacture Serial no',
    'Construction Number',
    'Ongoing Monitoring End date',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
};
// INITITALL WITH RISK RATING
const BULK_INITIAL_FILE_RISK_LIST_TYPE_COLUMNS = {
  i: [
    'S.no',
    'Full Name',
    'Gender',
    'D.O.B (yyyy/mm/dd)',
    'Nationality',
    'SSN',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'Phone No',
    'Email',
    'PAN',
    'National ID',
    'Alias',
    'Age',
    'Occupation',
    'Income',
    'Product Type',
    'Onboarding Duration',
    'Onboarding Type',
    'Missed Payment',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
  e: [
    'S.no',
    'Org Name',
    'D.O.E (yyyy/mm/dd)',
    'Phone No',
    'Email',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Headquarters',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'CIN',
    'GST',
    'ISIN',
    'TIN/PAN',
    'Alias',
    'Incorporation',
    'Industry',
    'Income',
    'Product Type',
    'Onboarding Duration',
    'Onboarding Type',
    'Missed Payment',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
  v: [
    'S.no',
    'Vessel Name',
    'Mfd date (yyyy/mm/dd)',
    'Vessel Type',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Flag State',
    'Country of Jurisdiction',
    'Registered Owner',
    'Parent Company',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'IMO',
    'MMSI',
    'Alias',
  ],
  a: [
    'S.no',
    'Aircraft Name',
    'Mfd Date (yyyy/mm/dd)',
    'Program',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Aircraft Model',
    'Country of Jurisdiction',
    'Operator',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'Manufacture Serial no',
    'Construction Number',
    'Alias',
  ],
  b: [
    'S.no',
    'Name',
    'Alias',
    'DOB/DOM/DOE(yyyy/mm/dd)',
    'Nationality',
    'Gender',
    'Country 1',
    'Country 2',
    'Country 3',
    'Country 4',
    'Country of Jurisdiction',
    'Address 1',
    'Address 2',
    'Address 3',
    'Address 4',
    'ZIP Code 1',
    'ZIP Code 2',
    'ZIP Code 3',
    'ZIP Code 4',
    'TIN/PAN',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'National ID',
    'Phone No',
    'Email',
    'Headquarters',
    'CIN',
    'GST',
    'ISIN',
    'SSN',
    'Vessel Type',
    'Flag State',
    'MMSI',
    'Registered Owner',
    'Tonnage',
    'IMO',
    'Parent Company',
    'Program',
    'Aircraft Operator/Owner',
    'Aircraft Model',
    'Manufacture Serial no',
    'Construction Number',
    'Applicant ID',
    'Application ID',
    'SDFC Lead ID',
    'Branch Name',
    'Product Line',
    'Pennant Analyst Name',
    'Analyst Email ID',
    'Field 1',
    'Field 2',
    'Field 3',
  ],
};
const BULK_INITIAL_FILE_TRANSACTION_LIST_TYPE_COLUMNS = {
  i: [
    'S.no',
    'Transaction ID',
    'Transaction Type',
    'Channel Type',
    'Product Type',
    'Amount',
    'Currency',
    'Transaction Date',
    'Type',
    'Full Name',
    'Alias',
    'Gender',
    'D.O.B (yyyy/mm/dd)',
    'Nationality',
    'Country',
    'Country of Jurisdiction',
    'Address',
    'ZIP Code',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'Phone No',
    'Email',
    'PAN',
    'National ID',
    'SSN',
    'KYC ID',
    'Account Number',
    'IFSC Code',
    'Bank Name',
  ],
  e: [
    'S.no',
    'Transaction ID',
    'Transaction Type',
    'Channel Type',
    'Product Type',
    'Amount',
    'Currency',
    'Transaction Date',
    'Type',
    'Org Name',
    'Alias',
    'Phone No',
    'Email',
    'Country',
    'D.O.E (yyyy/mm/dd)',
    'Headquarters',
    'Country of Jurisdiction',
    'Address',
    'ZIP Code',
    'KYC ID',
    'CIN',
    'GST',
    'ISIN',
    'TIN/PAN',
    'Account Number',
    'IFSC Code',
    'Bank Name',
  ],
  b: [
    'S.no',
    'Transaction ID',
    'Transaction Type',
    'Channel Type',
    'Product Type',
    'Amount',
    'Currency',
    'Transaction Date',
    'Type',
    'Name',
    'Alias',
    'Gender',
    'DOB/DOM/DOE(yyyy/mm/dd)',
    'Nationality',
    'Country',
    'Headquarters',
    'Country of Jurisdiction',
    'Address',
    'ZIP Code',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'Phone No',
    'Email',
    'National ID',
    'KYCID',
    'CIN',
    'SSN',
    'GST',
    'ISIN',
    'TIN/PAN',
    'Vessel Type',
    'Flag State',
    'Program',
    'Aircraft Operator/Owner',
    'Manufacture Serial no',
    'MMSI',
    'Registered Owner',
    'Tonnage',
    'Aircraft Model',
    'IMO',
    'Construction Number',
    'Parent Company',
    'Account Number',
    'IFSC Code',
    'Bank Name',
  ],
};

const BULK_ONGOING_FILE_TRANSACTION_LIST_TYPE_COLUMNS = {
  i: [
    'S.no',
    'Transaction ID',
    'Transaction Type',
    'Channel Type',
    'Product Type',
    'Amount',
    'Currency',
    'Transaction Date',
    'Type',
    'Full Name',
    'Alias',
    'Gender',
    'D.O.B (yyyy/mm/dd)',
    'Nationality',
    'Country',
    'Country of Jurisdiction',
    'Address',
    'ZIP Code',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'Phone No',
    'Email',
    'PAN',
    'National ID',
    'SSN',
    'KYC ID',
    'Account Number',
    'IFSC Code',
    'Bank Name',
    'Ongoing Monitoring End date',
  ],
  e: [
    'S.no',
    'Transaction ID',
    'Transaction Type',
    'Channel Type',
    'Product Type',
    'Amount',
    'Currency',
    'Transaction Date',
    'Type',
    'Org Name',
    'Alias',
    'Phone No',
    'Email',
    'Country',
    'D.O.E (yyyy/mm/dd)',
    'Headquarters',
    'Country of Jurisdiction',
    'Address',
    'ZIP Code',
    'KYC ID',
    'CIN',
    'GST',
    'ISIN',
    'TIN/PAN',
    'Account Number',
    'IFSC Code',
    'Bank Name',
    'Ongoing Monitoring End date',
  ],
  b: [
    'S.no',
    'Transaction ID',
    'Transaction Type',
    'Channel Type',
    'Product Type',
    'Amount',
    'Currency',
    'Transaction Date',
    'Type',
    'Name',
    'Alias',
    'Gender',
    'DOB/DOM/DOE(yyyy/mm/dd)',
    'Nationality',
    'Country',
    'Headquarters',
    'Country of Jurisdiction',
    'Address',
    'ZIP Code',
    'Passport Number',
    'Voter ID',
    'Driving License',
    'Phone No',
    'Email',
    'National ID',
    'KYCID',
    'CIN',
    'SSN',
    'GST',
    'ISIN',
    'TIN/PAN',
    'Ongoing Monitoring End date',
    'Vessel Type',
    'Flag State',
    'Program',
    'Aircraft Operator/Owner',
    'Manufacture Serial no',
    'MMSI',
    'Registered Owner',
    'Tonnage',
    'Aircraft Model',
    'IMO',
    'Construction Number',
    'Parent Company',
    'Account Number',
    'IFSC Code',
    'Bank Name',
  ],
};
const PRIVATE_LIST_FILE_TYPE_COLUMNS = {
  individual: [
    'Name',
    'Alias_1',
    'Alias_2',
    'Alias_3',
    'Gender',
    'Nationality',
    'Date_Of_Birth',
    'Country_1',
    'City_1',
    'State_1',
    'Full_Address_1',
    'Country_2',
    'City_2',
    'State_2',
    'Full_Address_2',
    'Zipcode',
    'National_id',
    'Voter_id',
    'Driving_License',
    'Country_of_jurisdiction',
    'Passport',
    'PAN',
    'TIN',
    'Phone No',
    'Email ID',
    'Sanction_List_Source',
    'Sanction_List_Authority',
    'Sanction_list_Host_Country',
    'Sanction_List_URL',
    'Sanction_List_Type',
    'Sanction_List_Description',
  ],
  entity: [
    'Name',
    'Alias_1',
    'Alias_2',
    'Alias_3',
    'Date_Of_Establishment',
    'Country_of_jurisdiction',
    'Headquarters',
    'Email ID',
    'President',
    'Country_1',
    'City_1',
    'State_1',
    'Full_Address_1',
    'Country_2',
    'City_2',
    'State_2',
    'Full_Address_2',
    'Zipcode',
    'CIN',
    'ISIN',
    'PAN',
    'GST',
    'Sanction_List_Source',
    'Sanction_List_Authority',
    'Sanction_list_Host_Country',
    'Sanction_List_URL',
    'Sanction_List_Type',
    'Sanction_List_Description',
  ],
  vessel: [
    'Name',
    'Alias_1',
    'Alias_2',
    'Alias_3',
    'Date_of_manufacturing',
    'Country_1',
    'Country_2',
    'Country_of_jurisdiction',
    'Address',
    'Zipcode',
    'IMO',
    'MMSI',
    'Vessel_Type',
    'Ship_Owner',
    'Registered_Owner',
    'Parent_Company',
    'Operational_Company',
    'Call_Sign',
    'Tonnage',
    'Gross_Registered_tonnage',
    'Flag_State',
    'Sanction_List_Source',
    'Sanction_List_Authority',
    'Sanction_list_Host_Country',
    'Sanction_List_URL',
    'Sanction_List_Type',
    'Sanction_List_Description',
  ],
  aircraft: [
    'Name',
    'Alias_1',
    'Alias_2',
    'Alias_3',
    'Date_of_manufacturing',
    'Country_1',
    'Country_2',
    'Address',
    'Zipcode',
    'Country_of_jurisdiction',
    'Aircraft_owner',
    'Model',
    'Manufacture_Serial_Number',
    'Tail_Number',
    'Program_1',
    'Program_2',
    'Program_3',
    'Construction_No',
    'Sanction_List_Source',
    'Sanction_List_Authority',
    'Sanction_list_Host_Country',
    'Sanction_List_URL',
    'Sanction_List_Type',
    'Sanction_List_Description',
  ],
};

const ADD_TO_EXCLUSION_COLUMNS = [
  'Name',
  'Search ID',
  'Customer ID',
  'Search Type',
];

const DATE_COLUMN_NAMES = [
  'D.O.B (yyyy/mm/dd)',
  'Mfd date (yyyy/mm/dd)',
  'D.O.E (yyyy/mm/dd)',
  'Mfd Date (yyyy/mm/dd)',
  'Ongoing Monitoring End date',
  'DOB/DOM/DOE(yyyy/mm/dd)'
];
const DATE_INITIAL_COLUMN_NAMES = [
  'D.O.B (yyyy/mm/dd)',
  'Mfd date (yyyy/mm/dd)',
  'D.O.E (yyyy/mm/dd)',
  'Mfd Date (yyyy/mm/dd)',
  'DOB/DOM/DOE(yyyy/mm/dd)'
];

const AMS_NAME_COLUMNS = {
  name: ['Name'],
  keyword: ['Keyword', 'Country'],
};
const RISK_RATING_COLUMNS = {
  occupation: ['Occupation'],
  product: ['Product Type'],
  industry: ['Industry Type'],
};
const TRANSACTION_SCREENING_COLUMNS = {
  channel: ['Channel Type'],
  product: ['Product Type'],
  transaction: ['Transaction Type'],
};
const ONBOARDING_TYPE = [
  'Face-to-face Onboarding',
  'Virtual Onboarding',
  'Third Party Onboarding',
];
const TTR_COLUMNS = {
  insurance: [
    'Rentity ID',
    'Rentity Branch',
    'Submission Code',
    'Report Code',
    'Submission Date',
    'Currency Code',
    'Reporting Person Gender',
    'Reporting Person Title',
    'Reporting Person First Name',
    'Reporting Person Last Name',
    'Reporting Person Birthdate',
    'Reporting Person SSN',
    'Reporting Person ID Number',
    'Reporting Person Nationality1',
    'Reporting Person Contact Type',
    'Reporting Person Communication Type',
    'Reporting Person Country Prefix',
    'Reporting Person Phone Number',
    'Reporting Person Address Type',
    'Reporting Person Address',
    'Reporting Person Town',
    'Reporting Person City',
    'Reporting Person Zip',
    'Reporting Person Country Code',
    'Reporting Person State',
    'Reporting Person Occupation',
    'Reporting Person Email',
    'Location Address Type',
    'Location Address',
    'Location Town',
    'Location City',
    'Location Zip',
    'Location Country Code',
    'Location State',
    'Reason',
    'Transaction Number',
    'Transaction Location',
    'Transaction Description',
    'Date Transaction',
    'Teller',
    'Authorized',
    'Transmode Code',
    'Amount Local',
    'From Funds Code',
    'Conductor First Name',
    'Conductor Last Name',
    'From Account Institution Name ',
    'From Account Swift',
    'From Account Account',
    'From Country',
    'To Funds Code',
    'To Account Institution Name',
    'To Account Institution Code',
    'To Account Branch',
    'To Account Account',
    'To Account Currency Code',
    'To Account Account Name',
    'To Account Personal Account Type',
    'To Account Is Primary',
    'To Account Role',
    'To Account Person Gender',
    'To Account Person First Name',
    'To Account Person Middle Name',
    'To Account Person Last Name',
    'To Account Person Birthdate',
    'To Account Person Birth Place',
    'To Account Person SSN',
    'To Account Person Nationality1',
    'To Account Person Residence',
    'To Account Person Phone Contact Type',
    'To Account Person Phone Communication Type',
    'To Account Person Phone Number',
    'To Account Address Type',
    'To Account Person Address',
    'To Account Person Address Town',
    'To Account Person Address City',
    'To Account Person Address Zip',
    'To Account Person Address Country Code',
    'To Account Person Occupation',
    'To Account Person Identification Type',
    'To Account Person Identification Number',
    'To Account Person Identification Issued By',
    'To Account Person Identification Issue Country',
    'To Account Opened',
    'To Account Balance',
    'To Account Date Balance',
    'To Account Status Code',
    'To Account Comments',
    'To Country',
  ],
  capital_market: [
    'Rentity ID',
    'Rentity Branch',
    'Submission Code',
    'Report Code',
    'Reporting User Code',
    'Report Date',
    'Currency Code',
    'Location Address Type',
    'Location Address',
    'Location Town',
    'Location City',
    'Location Zip',
    'Location Country Code',
    'Transaction Number',
    'Transaction Location',
    'Transaction Description',
    'Date Transaction',
    'Transmode Code',
    'Amount Local',
    'From Funds Code',
    'From Account Institution Name',
    'From Account Swift',
    'From Account Institution Country',
    'From Account Non Bank Institution',
    'From Account Branch',
    'From Account Account',
    'From Account Currency Code',
    'From Account Account Name',
    'From Account Comments',
    'From Country',
    'To Funds Code',
    'To Funds Comment',
    'To Country',
    'To Account Institution Name',
    'To Account Swift',
    'To Account Institution Country',
    'To Account Branch',
    'To Account Account',
    'To Account Currency Code',
    'To Account Account Name',
    'Related Person is Primary',
    'Related Person Role',
    'Related Person Gender',
    'Related Person First Name',
    'Related Person Middle Name',
    'Related Person Last Name',
    'Related Person Birthdate',
    'Related Person Birth Place',
    'Related Person Birth Country',
    'Related Person Mother Name',
    'Related Person Father Name',
    'Related Person Grandfather Name',
    'Related Person Spouse',
    'Related Person Alias',
    'Related Person SSN',
    'Related Person Nationality1',
    'Related Person Nationality2',
    'Related Person Nationality3',
    'Related Person Residence',
    'Related Person Occupation',
    'Related Person Email',
    'Related Person Tax Number',
    'Related Person Citizenship Number',
    'Related Person Tax Reg Number',
    'Related Person Source Of Wealth',
    'Related Person Contact Type',
    'Related Person Communication Type',
    'Related Person Phone Number',
    'Related Person Address Type',
    'Related Person Address',
    'Related Person Town',
    'Related Person City',
    'Related Person Zip',
    'Related Person State',
    'Related Person Country Code',
    'Related Person Identification Type',
    'Related Person Identification Number',
    'Related Person Identification Issue Date',
    'Related Person Identification Issued By',
    'Related Person Identification Issue Country',
    'Comments',
  ],
  right_share: [
    'Transaction Number',
    'Transaction Location',
    'Transaction Description 1',
    'Transaction Description 2',
    'Date Transaction',
    'Amount Local',
    'From Funds Type',
    'From Funds Comment',
    'From Account Institution Name',
    'From Account Swift',
    'From Account Institution Country',
    'From Account Branch',
    'From Account Number',
    'From Account Currency Code',
    'From Applicant Name',
    'From Account iban',
    'From Account Type',
    'From Country',
    'To Funds Code',
    'To Funds Comment',
    'To Account Institution Name',
    'To Account Swift',
    'To Account Institution Country',
    'To Account Branch',
    'To Account DMAT Account No./Shareholder No.',
    'To Account Currency Code',
    'To Account Applicant Name',
    'To Account iban',
    'To Account Type',
    'To Country',
    'Comments',
  ],
};
const AUDIT_TRAIL_PARAMETERS = {
  sectionData: [
    {
      label: 'Sanction Configuration',
      value: 'SanctionConfiguration',
    },
    {
      label: 'Sanctions',
      value: 'Sanctions',
    },
    {
      label: 'AMS',
      value: 'AMS',
    },
    {
      label: 'PEP',
      value: 'PEP',
    },
    {
      label: 'Transaction Parameter',
      value: 'TransactionParameter',
    },
    {
      label: 'Risk Rating',
      value: 'RiskRating',
    },
    {
      label: 'Suppression Logic',
      value: 'SuppressionLogic',
    },
  ],
  riskRatingData: [
    {
      label: 'Risk Parameter',
      value: 'RiskParameter',
    },
    {
      label: 'Re-Kyc Configuration',
      value: 'ReKYCConfiguration',
    },
    {
      label: 'Individual Parameters Configuration',
      value: 'IndividualParametersConfiguration',
    },
    {
      label: 'Individual Parameters Weightage',
      value: 'IndividualParametersWeightage',
    },
    {
      label: 'Entity Parameters Configuration',
      value: 'EntityParametersConfiguration',
    },
    {
      label: 'Entity Parameters Weightage',
      value: 'EntityParametersWeightage',
    },
  ],
};
const INDIVIDUAL_DEDUPE_PARAMETER = [
  { value: 'pan', label: 'PAN' },
  { value: 'national_id', label: 'National ID' },
  { value: 'passport_number', label: 'Passport Number' },
  { value: 'voters_id', label: "Voter's ID" },
  { value: 'drivers_license', label: 'Driving License' },
];
const AIRCRAFT_DEDUPE_PARAMETER = [
  { value: 'manufacture_serial_number', label: 'MSN' },
  { value: 'construction_number', label: 'Construction No.' },
];

const VESSEL_DEDUPE_PARAMETER = [
  { value: 'imo', label: 'IMO No.' },
  { value: 'mmsi', label: 'MMSI No.' },
];

const ENTITY_DEDUPE_PARAMETER = [
  { value: 'tin', label: 'TIN' },
  { value: 'isin', label: 'ISIN' },
  { value: 'cin', label: 'CIN' },
  { value: 'gst', label: 'GST' },
];

const MANAGER_SETUP_ROUTES = [
  'access-management',
  'rule-engine',
  'suppression-logic',
  'exclusion-list',
  'history',
  'sar-configuration',
];

const RISK_RATING_MENU_ITEMS = {
  individual: [
    { value: 'age', label: 'Age' },
    { value: 'occupation', label: 'Occupation' },
    { value: 'income', label: 'Income' },
    { value: 'sanctions', label: 'Sanctions' },
    { value: 'pep', label: 'PEP' },
    { value: 'ams', label: 'AMS' },
    { value: 'country', label: 'Country' },
    { value: 'product', label: 'Product type' },
    { value: 'onboarding_duration', label: 'Onboarding Duration' },
    { value: 'onboarding_type', label: 'Onboarding Type' },
    { value: 'missed_payment', label: 'Missed Payment' },
    { value: 'address', label: 'Address' },
    { value: 'zipcode', label: 'Zipcode' },
  ],
  entity: [
    { value: 'incorporation', label: 'Incorporation Period' },
    { value: 'industry', label: 'Industry Type' },
    { value: 'income', label: 'Income' },
    { value: 'sanctions', label: 'Sanctions' },
    { value: 'ams', label: 'AMS' },
    { value: 'pep', label: 'PEP' },
    { value: 'country', label: 'Country' },
    { value: 'product', label: 'Product type' },
    { value: 'onboarding_duration', label: 'Onboarding Duration' },
    { value: 'onboarding_type', label: 'Onboarding Type' },
    { value: 'address', label: 'Address' },
    { value: 'zipcode', label: 'Zipcode' },
    { value: 'missed_payment', label: 'Missed Payment' },
  ],
};
const RISK_RATING_CONTINOUS_STATIC_PARAMETERS = [
  'age',
  'income',
  'onboarding_duration',
  'incorporation',
  'missed_payment',
];
const RISK_RATING_STATIC_PARAMETERS = [
  'age',
  'income',
  'onboarding_duration',
  'incorporation',
  'missed_payment',
  'occupation',
  'country',
  'industry',
  'product',
  'zipcode',
  'address',
  'onboarding_type',
];
const constants = {
  countries,
  days,
  marks,
  riskRatingMarks,
  reminderMailMarks,
  snoozingFrequencyMarks,
  dobMarks,
  SEARCH_TYPES,
  SEARCH_TYPES_TRANSACTION,
  SCREEN_TYPES,
  ASSIGNED_OPTIONS,
  FINAL_VERDICTS,
  LIST_TYPES,
  BULK_FILE_LIST_TYPE_COLUMNS,
  BULK_FILE_RISK_LIST_TYPE_COLUMNS,
  PRIVATE_LIST_FILE_TYPE_COLUMNS,
  TTR_COLUMNS,
  alertBySamsMark,
  CURRENCIES,
  ADD_TO_EXCLUSION_COLUMNS,
  DATE_COLUMN_NAMES,
  AMS_NAME_COLUMNS,
  BULK_INITIAL_FILE_LIST_TYPE_COLUMNS,
  BULK_INITIAL_FILE_RISK_LIST_TYPE_COLUMNS,
  DATE_INITIAL_COLUMN_NAMES,
  ONBOARDING_TYPE,
  RISK_RATING_COLUMNS,
  ENTITY_DEDUPE_PARAMETER,
  VESSEL_DEDUPE_PARAMETER,
  AIRCRAFT_DEDUPE_PARAMETER,
  INDIVIDUAL_DEDUPE_PARAMETER,
  TRANSACTION_SCREENING_COLUMNS,
  BULK_INITIAL_FILE_TRANSACTION_LIST_TYPE_COLUMNS,
  BULK_ONGOING_FILE_TRANSACTION_LIST_TYPE_COLUMNS,
  AUDIT_TRAIL_PARAMETERS,
  MANAGER_SETUP_ROUTES,
  RISK_RATING_MENU_ITEMS,
  RISK_RATING_STATIC_PARAMETERS,
  RISK_RATING_CONTINOUS_STATIC_PARAMETERS,
};
export default constants;
