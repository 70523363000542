// @ts-nocheck
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import keycloak from '../keycloak-config';
import {
  selectAuth,
  setAuth,
  setPanelNavigation,
} from '../state/slices/appSlice';

import { API_ENDPOINTS } from '../const/ApiEndpoints';

/**
 * @function useAuth
 * @description a custom hook which handles APIs related to user's authentication
 * @returns {object} object
 */
const useAuth = () => {
  const { isLoggedIn, user, panelNavigation, org } = useSelector(selectAuth);
  const [userdetails, setUserdetails] = useState({});
  const [refreshTokenIntervalObj, setRefreshTokenIntervalObj] = useState();
  const [panelData, setPanelData] = useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  /**
   * @function login
   * @description A function to post user's data for authentication
   * @returns {Promise} returns an axios promise
   */
  const login = (data) => {
    setIsLoading(true);
    return axios
      .post(API_ENDPOINTS.LOGIN, data, {
        headers: {
          org: data.accountId,
        },
      })
      .then(
        (res) => {
          setIsLoading(false);
          setUserdetails({ ...res?.data, org: data.accountId });
          enqueueSnackbar('OTP sent successfully', {
            variant: 'success',
          });
        },
        () => {
          setIsLoading(true);
        }
      );
  };
  const apiLogout = () => {
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.LOGOUT).then(
      () => {
        enqueueSnackbar('System logged out successfully', {
          variant: 'success',
        });
      },
      () => {
        setIsLoading(true);
      }
    );
  };
  /**
   * @function loginOtp
   * @description A function to post otp
   * @returns {Promise} returns an axios promise
   */

  const loginOtp = (data, history) => {
    const body = {
      username: userdetails?.username,
      email: userdetails?.email,
      otp: data.otp,
    };
    return axios
      .post(API_ENDPOINTS.OTP, body, {
        headers: {
          org: userdetails?.org,
        },
      })
      .then((res) => {
        if (res.data.length !== 0) {
          dispatch(
            setAuth({
              isLoggedIn: true,
              token: res.data.tokens.access,
              refresh: res.data.tokens.refresh,
              user: res.data.user,
              org: userdetails?.org,
            })
          );
          dispatch(
            setPanelNavigation({
              panelNavigation: res?.data?.panel_navigation,
            })
          );

          // Determine target route based on panelNavigation
          let targetRoute = '/analyst-dashboard'; // default route
          if (
            res?.data?.panel_navigation?.['manager-dashboard']?.route ===
            '/manager-dashboard'
          ) {
            targetRoute = '/manager-dashboard';
          } else if (
            res?.data?.panel_navigation?.['admin-dashboard']?.route ===
            '/admin-dashboard'
          ) {
            targetRoute = '/admin-dashboard';
          }
          history.push(targetRoute);

          if (window.apm) {
            window.apm.setUserContext({
              username: res?.data?.user?.username,
              id: res?.data?.user?.role,
              email: res?.data?.user?.email,
            });
          }

          enqueueSnackbar('Login successfully', {
            variant: 'success',
          });
        }
      })
      .catch(() => {
        // Handle errors if needed
      });
  };

  /**
   * @function refreshToken
   * @description A function for refresh Token
   * @returns {Promise} returns an axios promise
   */
  const refreshToken = (refresh) => {
    return axios
      .post(API_ENDPOINTS.REFRESH_TOKEN, {
        refresh,
        loader: false,
      })
      .then(
        (res) => {
          dispatch(
            setAuth({
              isLoggedIn: true,
              token: res.data.access,
              refresh: res.data.refresh,
              user,
              panelNavigation,
              org,
            })
          );
        },
        () => {}
      );
  };

  /**
   * @function setRefreshTokenInterval
   * @description A function to set Refresh Token Interval
   * @returns {Promise} returns an axios promise
   */
  const setRefreshTokenInterval = () => {
    const interval = setInterval(() => {
      refreshToken(localStorage.getItem('refreshToken'));
    }, 250000);
    if (refreshTokenIntervalObj) {
      clearInterval(refreshTokenIntervalObj);
    }
    setRefreshTokenIntervalObj(interval);
  };

  const logout = () => {
    apiLogout().then(() => {
      dispatch(
        setAuth({
          isLoggedIn: false,
          token: null,
          user: null,
          refresh: null,
          username: null,
          org: null,
        })
      );
      sessionStorage.clear();
    });
  };
  const keycloakUserLogout = () => {
    window.KEYCLOAK_CONFIG?.logout({
      redirectUri: `${window.location.origin}/org`,
    }).then(() => {
      window?.cookieStore?.delete('RECENTLY_CLICKED_NOTIFICATION_DATA');
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    });
  };
  /**
   * @function resetPassword
   * @description A function to reset password
   * @returns {Promise} returns an axios promise
   */
  const resetPassword = (data) => {
    return axios
      .post(API_ENDPOINTS.RESET_PASSWORD, data, {
        headers: {
          org: data.accountId,
        },
      })
      .then(
        () => {
          enqueueSnackbar('Password sent successfully', {
            variant: 'success',
          });
        },
        () => {}
      );
  };
  /**
   * @function changePassword
   * @description A function to change Password
   * @returns {Promise} returns an axios promise
   */
  const changePassword = (data) => {
    return axios.patch(API_ENDPOINTS.CHANGE_PASSWORD, data).then(
      (res) => {
        enqueueSnackbar(res.data.Msg, {
          variant: 'success',
        });
      },
      () => {}
    );
  };
  const fetchPanelInfo = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.PANEL_INFO).then(
      (res) => {
        dispatch(
          setPanelNavigation({
            panelNavigation: res?.data?.panel_navigation,
          })
        );
        return res.data;
      },
      (e) => {
        setIsLoading(false);
      }
    );
  };

  return {
    isLoggedIn,
    loginOtp,
    login,
    logout,
    resetPassword,
    changePassword,
    setRefreshTokenInterval,
    refreshTokenIntervalObj,
    user,
    isLoading,
    fetchPanelInfo,
    keycloakUserLogout,
    panelData,
  };
};
export default useAuth;
